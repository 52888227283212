/**
 * Author: leo Date: 05/03/2018
 * The service provides information on which features are enabled in the portal
 */
import {Environment} from "app/config/Environment";
import autobind from "autobind-decorator";
import {ClientFeatureFlags, IClientFeatureFlags, PlatformCode, InternalServerError} from "@sense-os/goalie-js";
import {ClientFeatureFlagKeys} from "./ClientFeatureFlagKeys";
import storage from "services/system/storage/Storage";
import {StorageKeys} from "services/system/storage/StorageKeys";
import createLogger from "../logger/createLogger";
import {SentryTags} from "../errorHandler/createSentryReport";
import {getSessionId} from "../auth/helpers/authStorage";
import {AppConfig} from "app/AppConfig";

type BackendFlags = Partial<{[k in ClientFeatureFlagKeys]: boolean}>;

@autobind
export class FeatureFlags {
	private log = createLogger("FeatureFlags", SentryTags.FeatureFlags);
	private _sdk: IClientFeatureFlags;

	/** Contains an object with feature flags either fetched from the BE or read from the local storage */
	private _backendFlags: BackendFlags = {};

	/** True if we want to rely fully on feature flag that was returned from backend */
	private _useBackendFlag: boolean = true;

	public async init(): Promise<void> {
		if (this._sdk) {
			return null;
		}

		// Initialise the SDK
		this._sdk = new ClientFeatureFlags();
		return await this.getFeatureFlagsFromBE();
	}

	/**
	 * Fetch feature flag from backend and assign it into local variable.
	 * Also stores the feature flags into localStorage.
	 */
	public async getFeatureFlagsFromBE(): Promise<void> {
		try {
			const token = getSessionId();

			const backendFlags = await this._sdk.fetchClientFeatureFlags(
				PlatformCode.Web,
				AppConfig.version,
				token || undefined,
			);

			this._backendFlags = this.parseFlags(backendFlags);
			this.log.debug("Feature flags from backend:", this._backendFlags);
			storage.write(StorageKeys.FEATURE_FLAGS, JSON.stringify(this._backendFlags));
		} catch (err) {
			if (err instanceof InternalServerError) {
				this.log.captureException(err);
			}

			// Try to use backendFlags from localStorage.
			const backendFlagsFromStorage = storage.read(StorageKeys.FEATURE_FLAGS) as string;
			// backendFlags does not exist, therefore we need to use the hardcoded ones instead.
			if (!backendFlagsFromStorage) {
				this._useBackendFlag = false;
				return;
			}

			const parsedJSON = JSON.parse(backendFlagsFromStorage);
			this.log.debug("Feature flags from storage: ", parsedJSON);
			if (parsedJSON && typeof parsedJSON === "object") {
				// Make sure that the feature flag object is valid: The values should be all `boolean`.
				const isValidFeatureFlags = Object.values(parsedJSON).every((value) => typeof value === "boolean");
				if (isValidFeatureFlags) {
					this._backendFlags = parsedJSON;
					return;
				}
			}
			this._useBackendFlag = false;
			this.log.warn("Feature flag from storage is not valid! Removing the feature flag from the storage..");
			storage.remove(StorageKeys.FEATURE_FLAGS);
		}
	}

	/** Converts the flags fetched from the BE into a friendlier format */
	private parseFlags(flagStrings: {code: string}[]): BackendFlags {
		const result: BackendFlags = {};
		flagStrings.forEach((flagString) => {
			const code = flagString.code;
			result[code] = true;
		});
		return result;
	}

	/** @inheritDoc */
	public get chat(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.chat);
	}

	/** @inheritDoc */
	public get clientNetwork(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.clientNetwork);
	}

	/** @inheritDoc */
	public get chatPresenceClient(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.chatPresenceClient);
	}

	/** @inheritDoc */
	public get chatPresenceTherapist(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.chatPresenceTherapist);
	}

	/** @inheritDoc */
	public get stepCount(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.stepCount);
	}

	/** @inheritDoc */
	public get dropdownMenuLanguages(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.dropdownMenuLanguages);
	}

	/** @inheritDoc */
	public get behaviorExperiment(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.behaviorExperiment, Environment.DEV_LOCAL);
	}

	/** @inheritDoc */
	public get clientsFeeds(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.clientsFeeds);
	}

	/** @inheritDoc */
	public get clientsBirthday(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.clientsBirthday, Environment.PROD);
	}

	/** @inheritDoc */
	public get backgroundNotifications(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.backgroundNotifications);
	}

	/** @inheritDoc */
	public get therapySession(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.therapySession);
	}

	/** @inheritDoc */
	public get chatReplyMessage(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.chatReplyMessage);
	}

	/** @inheritdoc */
	public get recurringPlannedEvents(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.recurringPlannedEvents);
	}

	/** @inheritdoc */
	public get recurringPlannedEventsCreation(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.recurringPlannedEventsCreation);
	}

	/** @inheritDoc */
	public get questionnaires(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.questionnaires);
	}

	/** @inheritDoc */
	public get twilioCall(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.twilioCall, Environment.PROD);
	}

	/** @inheritdoc */
	public get thoughtRecord(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.thoughtRecord, Environment.PROD);
	}

	/** @inheritdoc */
	public get customTrackers(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.customTracker, Environment.PROD);
	}

	/** @inheritdoc */
	public get conferenceCall(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.conferenceCall, Environment.PROD);
	}

	/** @inheritdoc */
	public get twilioConferenceCall(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.twilioConferenceCall, Environment.PROD);
	}

	public get emdr(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.emdr, Environment.PROD);
	}

	public get inactiveClient(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.inactiveClient, Environment.PROD);
	}

	public get customTrackerReminder(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.customTrackerReminder, Environment.PROD);
	}

	public get twilioNetworkQuality(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.twilioNetworkQuality, Environment.PROD);
	}

	public get chatboxDefaultOpen(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.chatboxDefaultOpen, Environment.PROD);
	}

	public get customTrackerPart2(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.customTrackerPart2, Environment.PROD);
	}

	public get treatmentStatus(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.treatmentStatus, Environment.PROD);
	}

	public get nicedayBlogSearch(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.nicedayBlogSearch, Environment.PROD);
	}

	public get warningBar(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.warningBar, Environment.PROD);
	}

	public get unverifiedDashboard(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.unverifiedDashboard);
	}

	public get privateNotes(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.privateNotes, Environment.PROD);
	}

	public get psychoEducationV2(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.psychoEducationV2, Environment.DEV);
	}

	public get customClientDetails(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.customClientDetails, Environment.PROD);
	}

	public get autoTimeTracking(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.autoTimeTracking, Environment.PROD);
	}

	public get timeTracking(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.timeTracking, Environment.PROD);
	}

	public get planActivityForm(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.planActivityForm, Environment.DEV);
	}

	public get fileSharing(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.fileSharing, Environment.ALPHA);
	}

	public get therapistJobSpecialization(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.therapistJobSpecialization, Environment.PROD);
	}

	public get accountSettings(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.accountSettings, Environment.DEV);
	}

	public get twoFactorAuthentication(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.twoFactorAuthentication, Environment.DEV);
	}

	public get portalForTablet(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.portalForTablet, Environment.PROD);
	}

	public get therapistEmailVerification(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.therapistEmailVerification, Environment.PROD);
	}

	public get conditionalEmdr(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.conditionalEmdr, Environment.DEV);
	}

	public get outgoingCallType(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.outgoingCallType, Environment.DEV);
	}

	public get calendar(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.calendar, Environment.DEV);
	}

	public get toolbarDataPage(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.toolbarDataPage, Environment.DEV);
	}

	public get dedicatedNotesPage(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.dedicatedNotesPage, Environment.DEV);
	}

	public get interventionPage(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.interventionPage, Environment.DEV);
	}

	public get therapistQrCode(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.therapistQrCode, Environment.DEV);
	}

	public get interventionNote(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.interventionNote, Environment.DEV);
	}
	public get interventionDiary(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.interventionDiary, Environment.DEV);
	}

	public get productFruits(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.productFruits, Environment.DEV);
	}

	public get interventionTreatmentGoal(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.interventionTreatmentGoal, Environment.DEV);
	}

	public get interventionPsychoEducation(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.interventionPsychoeducation, Environment.DEV);
	}
	public get interventionBehaviorExperiment(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.interventionBehaviorExperiment, Environment.DEV);
	}

	public get automaticTreatmentForTimeTracking(): boolean {
		return (
			this.checkFeature(ClientFeatureFlagKeys.autoTreatmentForTT, Environment.PROD) ||
			!this.checkFeature(ClientFeatureFlagKeys.treatmentStatus, Environment.PROD)
		);
	}

	public get postCallDialogs(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.postCallDialogs, Environment.DEV);
	}

	public get interventionCustomTracker(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.interventionCustomTracker, Environment.DEV);
	}

	public get interventionSession(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.interventionSession, Environment.DEV);
	}

	public get sessionsAndNotesDateUnrestricted(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.unrestrictedSessionAndNote, Environment.DEV);
	}

	public get topNavButtons(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.topNavButtons, Environment.DEV);
	}

	public get customTrackerDataPage(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.customTrackerDataPage, Environment.DEV);
	}

	public get callMediaStats(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.callMediaStats, Environment.DEV);
	}

	public get callMediaTrackStats(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.callMediaTrackStats, Environment.DEV);
	}

	public get therapistCaseload(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.therapistCaseload, Environment.DEV);
	}

	public get therapistOrgContacts(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.therapistOrgContacts, Environment.DEV);
	}

	public get callWithH264(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.callWithH264, Environment.DEV);
	}

	public get referralId(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.referralId, Environment.DEV);
	}

	public get trackersV3(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.trackersV3, Environment.DEV);
	}

	public get taskRegistration(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.taskRegistration, Environment.DEV);
	}

	public get interventionTaskRegistration(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.interventionTaskRegistration, Environment.DEV);
	}

	public get soloTherapistBehavior(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.soloTherapistBehavior, Environment.DEV);
	}

	public get assessment(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.assessment, Environment.DEV);
	}

	public get interventionAssessment(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.interventionAssessment, Environment.DEV);
	}

	public get callMediaRegionGll(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.callMediaRegionGll, Environment.DEV);
	}

	public get hideAssessmentTitleField(): boolean {
		// TODO remove this flag when app already support assessment title.
		return this.checkFeature(ClientFeatureFlagKeys.hideAssessmentTitle, Environment.DEV);
	}

	public get trackersV4(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.trackersV4, Environment.ALPHA);
	}

	public get callWarning(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.callWarning, Environment.DEV);
	}

	public get graphV2(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.graphV2, Environment.DEV);
	}

	public get graphV2AllTime(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.graphV2AllTime, Environment.DEV);
	}

	public get graphV2Overview(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.graphV2Overview, Environment.DEV);
	}

	public get timeTrackingExport(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.tt_export, Environment.DEV);
	}

	public get adminPage(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.adminPage, Environment.DEV);
	}

	public get adminPageMembers(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.adminMembersPage, Environment.DEV);
	}

	public get callExtendedReconnect(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.callExtendedReconnect, Environment.DEV);
	}

	public get presetsModule(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.presetsModule, Environment.DEV);
	}

	public get interventionPresetsModule(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.interventionPresets, Environment.DEV);
	}

	public get sessionMeetInfo(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.sessionMeetInfo, Environment.DEV);
	}

	public get supportOrgTherapists(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.supportOrgTherapists, Environment.DEV);
	}

	public get groupTherapy(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.groupTherapy, Environment.DEV);
	}

	public get inviteClients(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.inviteClients, Environment.DEV);
	}

	public get customExercise(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.customExercise, Environment.DEV);
	}

	public get customExerciseRepetition(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.customExerciseRepetition, Environment.DEV);
	}

	public get interventionCustomExercise(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.interventionCustomExercise, Environment.DEV);
	}

	public get callNewStartScreen(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.callNewStartScreen, Environment.DEV);
	}

	public get singleArticlePsychoeducation(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.singleArticlePsychoeducation, Environment.DEV);
	}

	public get meetingAgora(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.meetingAgora, Environment.DEV);
	}

	public get meetingAgoraSdk(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.meetingAgoraSdk, Environment.DEV);
	}

	public get meetingAgoraPreferred(): boolean {
		return this.checkFeature(ClientFeatureFlagKeys.meetingAgoraPreferred, Environment.DEV);
	}

	/**
	 * Check if feature is enabled or not
	 */
	private checkFeature(key: ClientFeatureFlagKeys, env: Environment = Environment.PROD) {
		if (this._useBackendFlag) {
			return this._backendFlags[key];
		}
		return this.isFeatureAvailable(key, env);
	}

	/**
	 * Check if the feature is available based on which active environment from AppConfig
	 *
	 * @param {string} featureName
	 * @param {Environment} environment
	 */
	private isFeatureAvailable(featureName: string, environment: Environment): boolean {
		const isAvailable: boolean = {
			[Environment.DEV_LOCAL]: AppConfig.isDev,
			[Environment.DEV]: AppConfig.isDev,
			[Environment.ALPHA]: AppConfig.isDev || AppConfig.isAlpha,
			[Environment.PROD]: true,
		}[environment];

		return isAvailable;
	}
}

const featureFlags = new FeatureFlags();

export default featureFlags;
